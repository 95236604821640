<template>
  <div :key="requestId">
    <ClinicDeatil
      backRoute="DoctorClinic"
      :isClinicJoinRequest="true"
      :alreadyResponded="alreadyResponded"
      :alreadyRespondedMessage="alreadyRespondedMessage"
    ></ClinicDeatil>
  </div>
</template>

<script>
import ClinicDeatil from "@/views/components/clinics/ClinicDetailPage.vue";

export default {
  components: {
    ClinicDeatil,
  },
  data: () => {
    return {
      alreadyRespondedMessage: "",
      alreadyResponded: false,
      requestId: "",
    };
  },
  methods: {
    maintainViewData() {
      this.$vs.loading();
      this.$store
        .dispatch(
          "general/getUserDetailById",
          this.$store.state.AppActiveUser._id
        )
        .then((response) => {
          const userDetail = response.data.data;
          const requestId = this.$route.params.requestId;
          const requestDetail = userDetail.requestsToJoin.find(
            (item) => item._id === requestId
          );
          if (!requestDetail) {
            this.alreadyResponded = true;
            this.alreadyRespondedMessage =
              "The requested operation can't be completed as data not found on database.";
          } else if (requestDetail.status === "Accepted") {
            this.alreadyResponded = true;
            this.alreadyRespondedMessage =
              "The invitaion has already been accepted.";
          } else if (requestDetail.status === "Rejected") {
            this.alreadyResponded = true;
            this.alreadyRespondedMessage =
              "The invitaion has already been rejected.";
          } else {
            this.alreadyResponded = false;
            this.alreadyRespondedMessage =
              "The invitaion has already been rejected.";
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    forceRerender(newRequestId) {
      this.requestId = newRequestId;
    },
    respondInvitation(respondStatus) {
      this.$vs.loading();
      const params = {
        status: respondStatus,
        doctorId: this.$store.state.AppActiveUser._id,
        requestId: this.$route.params.requestId,
      };
      this.$store
        .dispatch("doctor/respondClinicJoinRequest", params)
        .then((response) => {
          this.$vs.notify({
            title: "Success",
            text: `Invitation ${respondStatus} Successfully.`,
            color: "success",
          });
          this.$router.push("/doctor/clinics");
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: err.data.message,
            color: "danger",
          });
          this.$router.push("/doctor/clinics");
          this.$vs.loading.close();
        });
    },
  },
  watch: {
    "$route.params.requestId": function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.maintainViewData();
        this.forceRerender(newVal);
      }
    },
  },
  created() {
    this.maintainViewData();
  },
};
</script>
